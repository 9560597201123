import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { DropdownFilter, PageTitle, Table } from "@common-components";
import "./userManagement.scss";
import { ClayInput } from "@clayui/form";
import { useEffect, useState } from "react";
import { ActionComponent } from "@components/ActionComponent/ActionComponent";
import { UserDeleteModal } from "./UserDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { setEditUserDetail,resetSelectedUserDetail, getUserDetailFlag, setUserDetailFlag, getFilterState, resetFilters, loadSCRTUsermanagementDetails, getSCRTUserList, setUpdateUserFlag, fetchRoleList, getTableRoleList, getUserRoleList, fetchTitleList, getTableTitleList, getUserTitleList, getEditTitleList } from "@store/console/scrtUserManagement/slice";
import UserDetail from "./UserDetail/UserDetail";
import ClayButton from "@clayui/button";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { IUpdateUserDetails } from "utils/interfaces/scrtUserManagement";

const UserManagement=()=>{

    const dispatch= useDispatch()

    const data:IUpdateUserDetails[]= useSelector(getSCRTUserList)

    const [searchValue, setSearchValue] = useState("")
    const [isSearchGoingOn,setIsSearchGoingOn] = useState(false)
    const [userDeleteFlag, setUserDeleteFlag] = useState(false)
    const [selectedUser,setSelectedUser] = useState("")

    const userDetailFlag= useSelector(getUserDetailFlag)
    const filterValue = useSelector(getFilterState);
    const tableRoleList = useSelector(getTableRoleList)
    const userRoleList = useSelector(getUserRoleList)
    const tableTitleList = useSelector(getTableTitleList)
    const userTitleList = useSelector(getUserTitleList)
    const editTitleList = useSelector(getEditTitleList)
    const pageTitle = `Broadcom SCRT User Management`;

    useEffect(()=>{
        dispatch(fetchRoleList())
        dispatch(fetchTitleList())
        dispatch(setUserDetailFlag(false))
        dispatch(resetFilters());
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    
    const showUserTable=(val:string)=>{
        const data:string=val
        dispatch(setUpdateUserFlag(false))
        dispatch(setUserDetailFlag(false))
        dispatch(resetSelectedUserDetail())
        !data && dispatch(resetFilters());
        
    }
    
    useEffect(()=>{
        const delayDebounceFn = setTimeout(() => {
            if (isSearchGoingOn) {
            setIsSearchGoingOn(false);
            }
        }, 750);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    },[searchValue])  // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnChange=(val:string)=>{
        setSearchValue(val)
    }

    const createUser=()=>{
        dispatch(resetSelectedUserDetail())
        dispatch(setUserDetailFlag(true))
    }

    const confirmDelete=(list:string)=>{
        document.body.style.overflow = "visible";
        setUserDeleteFlag(false)
    }

    const handleResetFilter = () => {
        let isExistingFilter = false;
    
        // check if any filters are present
        if (Object.keys(filterValue).length === 0) {
          isExistingFilter = false;
        } else {
          for (let key in filterValue) {
            if (filterValue.hasOwnProperty(key)) {
                if(filterValue[key] !== ""){
                    isExistingFilter=true
                    break ;
                }else{
                    isExistingFilter=false
                }
            }
          }
        }
        // reset filters only if there are any filters
        if (isExistingFilter) {
          dispatch(resetFilters());
        }
      };
    
    const handleFilter = (val: any) => {
        !userDetailFlag && dispatch(loadSCRTUsermanagementDetails(val))
    };

    const USER_MANAGEMENT_COLUMNS = [
        {
            headerName: "Email Address",
            field: "userId",
            sortable: true,
            filter: { show: true },
            width:"6rem"
        },
        {
            headerName: "Role",
            field: "role",
            sortable: true,
            filter: { show: true },
            filterRenderer: <DropdownFilter options={tableRoleList} />,
            width: "9rem"
        },
        {
            headerName: "Title",
            field: "title",
            sortable: true,
            filter: { show: true },
            filterRenderer: <DropdownFilter options={tableTitleList} />,
            width: "9rem"
        },
        { headerName: "First Name", field: "firstName", sortable: true, width: "8rem"},
        { headerName: "Last Name", field: "lastName", sortable: true, width: "8rem"},
        { headerName: "Access", field: "access", sortable: true, width: "6rem"},
        { headerName: "Created By", field: "createdBy", sortable: true, width:"8rem" },
        { headerName: "Created Date", field: "createdTime", sortable: true, width:"9rem" },
        {
            headerName: "Modified By",
            field: "updatedBy",
            sortable: true,
            width:"8rem"
        },
        {
            headerName: "Last Modified",
            field: "updatedTime",
            sortable: true,
            width:"9rem"
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            filter: { show: true },
            filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
            cellRenderer: (
            <ActionComponent
                rowData={data}
                showDelete={false}
                disabledDownload={true}
                hidePreview={true}
                showEdit={true}
                deleteRow={(rowData:any) => {
                    setUserDeleteFlag(true)
                    setSelectedUser(rowData.userId)
                }}
                editUser={(rowData:IUpdateUserDetails) => {
                    dispatch(setUserDetailFlag(true))
                    dispatch(setEditUserDetail(rowData))
                }}
            ></ActionComponent>
            ),
            width:"10rem"
        },
    ];

    return(
        <div>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row justify="start" className="align-items-center">
                    <ClayLayout.Col className="d-flex align-items-center">
                        <PageTitle title={pageTitle} />
                    </ClayLayout.Col>
                </ClayLayout.Row>
                <ClayLayout.Row>
                    <ClayCard className="m-3 w-100">
                        {
                            userDetailFlag ? 
                            <UserDetail 
                                showUserTable={showUserTable} 
                                userRoleList={userRoleList}
                                userTitleList={userTitleList}
                                editTitleList={editTitleList}
                            />
                            :
                            <ClayCard.Body className="p-4">
                                <ClayLayout.Row className="mb-4">
                                    <ClayLayout.Col className="d-flex align-items-center flex-row-reverse">
                                        <ClayButton 
                                            displayType="primary" 
                                            type="button" 
                                            onClick={createUser}
                                        >
                                            Add User
                                        </ClayButton>
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                                <Table
                                    rowClassName="user-management-logs"
                                    headClassName= {"user-management-table-filter"}
                                    definitions={USER_MANAGEMENT_COLUMNS}
                                    dataSource={data}
                                    borderedColumns={false}
                                    borderless={true}
                                    headVerticalAlignment={"middle"}
                                    headingNoWrap={true}
                                    hover={false}
                                    noWrap={true}
                                    responsive={true}
                                    responsiveSize={"sm"}
                                    striped={true}
                                    tableVerticalAlignment={"middle"}
                                    handleFilter={handleFilter}
                                    filterValue={filterValue}
                                    showDeltasDropDown={true}
                                    checkDisabledRow={false}
                                    showPagination={true}
                                />
                            </ClayCard.Body>
                        }
                        
                    </ClayCard>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
            {
                userDeleteFlag && (
                <UserDeleteModal 
                    confirmDelete={confirmDelete}
                    userDetail={selectedUser}
                    modalClose={() => {
                        setSelectedUser("")
                        setUserDeleteFlag(false)
                    }}
                />
                )
            }
        </div>
    )
}

export default UserManagement;