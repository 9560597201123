import ClayCard from "@clayui/card"
import ClayForm, { ClayToggle } from "@clayui/form";
import ClayLayout from "@clayui/layout";
import { FormControl, PageTitle } from "@common-components";
import { fetchCatalogVersionDetails, getCatalogUpdateFlag, getCatalogVersionDetail, updateCatalogDetails } from "@store/console/productCatalogManagement/slice";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClayButton from "@clayui/button";
import * as Yup from "yup";
import { ICatalogDetailObj, ICatalogDetails, IUpdatePayload } from "utils/interfaces/productCatalog";

const ProductCatalogManagement=()=>{

    const dispatch=useDispatch()
    const formref=useRef(null)
    
    const catalogDetail:ICatalogDetails[] = useSelector(getCatalogVersionDetail)
    const updateFlag:boolean = useSelector(getCatalogUpdateFlag)

    const [catalogDetailObj,setCatalogDetailObj] = useState<ICatalogDetailObj>({
        warningRelease: 0,
        errorRelease: 0,
        warningCheck: 0,
        errorCheck: 0
    })
    const [warningToggle,setWarningToggle] = useState<boolean>(false)
    const [errorToggle,setErrorToggle] = useState<boolean>(false)
    const [updateEnable,setUpdateEnable] = useState<boolean>(false)
    const pageTitle="Product Catalog Management"

    const dateFormat=(dateVal:any)=>{
        let dateStr=new Date(dateVal)
        let finalStr=""
        if(dateVal!==""){
          let year=dateStr.getFullYear()
          let month=''+(dateStr.getMonth()+1)
          let day=''+dateStr.getDate()
          if(month.length<2){
            month='0'+month
          }
          if(day.length<2){
            day='0'+day
          }
          finalStr=`${year}-${month}-${day}`
        }
        return finalStr
    }

    useEffect(()=>{
        dispatch(fetchCatalogVersionDetails())
    },[])

    useEffect(()=>{
        updateFlag && dispatch(fetchCatalogVersionDetails())
    },[updateFlag])

    useEffect(()=>{
        setCatalogDetailObj({
            warningRelease : Number(catalogDetail[3]?.value),
            errorRelease : Number(catalogDetail[2]?.value),
            warningCheck : catalogDetail[1]?.value,
            errorCheck : catalogDetail[0]?.value 
        })
        setWarningToggle(catalogDetail[1]?.value === 1 ? true : false)
        setErrorToggle(catalogDetail[0]?.value === 1 ? true : false)
    },[catalogDetail])

    useEffect(()=>{
        if(
            Number(catalogDetailObj.warningRelease) !== Number(catalogDetail[3]?.value) || 
            Number(catalogDetailObj.errorRelease) !== Number(catalogDetail[2]?.value) || 
            Number(catalogDetailObj.warningCheck) !== Number(catalogDetail[1]?.value) ||
            Number(catalogDetailObj.errorCheck) !== Number(catalogDetail[0]?.value)
        ){
            setUpdateEnable(true)
        }else{
            setUpdateEnable(false)
        }
    },[catalogDetailObj])

    const toggleWarning=(val:boolean)=>{
        setWarningToggle(val)
        //setErrorToggle(!val)
        setCatalogDetailObj({...catalogDetailObj, warningCheck: val ? 1 : 0})
    }

    const toggleError=(val:boolean)=>{
        setErrorToggle(val)
        //setWarningToggle(!val)
        setCatalogDetailObj({...catalogDetailObj, errorCheck: val ? 1 : 0})
    }

    const updateWarningRelease=(val:any)=>{
        formref.current?.setFieldValue("warningRelease", val);
        setCatalogDetailObj({...catalogDetailObj, warningRelease:Number(val), warningCheck: warningToggle ? 1 : 0, errorCheck: errorToggle ? 1 : 0})
    }

    const updateErrorRelease=(val:any)=>{
        formref.current?.setFieldValue("errorRelease", val);
        setCatalogDetailObj({...catalogDetailObj, errorRelease:Number(val), warningCheck: warningToggle ? 1 : 0, errorCheck: errorToggle ? 1 : 0})
    }
    
    const validationSchema = Yup.object().shape({
        warningRelease: Yup.number().typeError("Please Enter a valid release").positive("Version can't be negative").required("Required field")
        .test("Warning Release test", function(value:any) {
            const { path, createError } = this;
            if(formref.current?.values.warningRelease < formref.current?.values.errorRelease)
                return createError({ path, message: "Warning Release must be higher than the Error Release. Please adjust values." });
            else if(formref.current?.values.warningRelease === formref.current?.values.errorRelease)
                return createError({ path, message: "Warning Release and Error Release cannot be the same. Please adjust one of the values." });
            else
                return true
        }),
        
        errorRelease: Yup.number().typeError("Please Enter a valid release").positive("Version can't be negative").required("Required field"),
    });

    const resetDetail=()=>{
        formref.current?.resetForm();
        setCatalogDetailObj({
            warningRelease : Number(catalogDetail[3].value),
            errorRelease : Number(catalogDetail[2].value),
            warningCheck : Number(catalogDetail[1].value),
            errorCheck : Number(catalogDetail[0].value) 
        })
        setWarningToggle(catalogDetail[1].value === 1 ? true : false)
        setErrorToggle(catalogDetail[0].value === 1 ? true : false)
    }

    const onSubmit=(data:any)=>{
        let payloadArr:IUpdatePayload[]=[
            {
                label:"warn_level",
                value:Number(data.warningRelease)
            },
            {
                label:"error_level",
                value:Number(data.errorRelease)
            },
            {
                label:"warn",
                value:warningToggle ? 1 : 0
            },
            {
                label:"error",
                value:errorToggle ? 1 : 0
            },
        ]
        dispatch(updateCatalogDetails(payloadArr))
    }
    return (
        <>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row justify="start" className="align-items-center">
                    <ClayLayout.Col className="d-flex align-items-center">
                        <PageTitle title={pageTitle} />
                    </ClayLayout.Col>
                </ClayLayout.Row>
                <ClayLayout.Row>
                    <ClayCard className="m-3 w-100">
                        <ClayCard.Body className="p-4">
                            <Formik
                                enableReinitialize={true}
                                destroyOnUnmount={false}
                                initialValues={catalogDetailObj}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                innerRef={formref}
                            >
                                {(formik) => (
                                <ClayForm noValidate onSubmit={formik.handleSubmit}>
                                    <ClayLayout.Row>
                                        <ClayLayout.Col size={2} className="mt-1">Warning Release: </ClayLayout.Col>
                                        <ClayLayout.Col size={2}>
                                            <FormControl
                                                control="text"
                                                name="warningRelease"
                                                //label="First Name"
                                                autoComplete={"off"}
                                                required={true}
                                                onChange={(e:any)=>{
                                                    updateWarningRelease(e.currentTarget.value)
                                                }}
                                            />
                                        </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated By: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{catalogDetail[3]?.updatedBy}</ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated Date: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{dateFormat(catalogDetail[3]?.updateDate)}</ClayLayout.Col>
                                    </ClayLayout.Row>
                                    <ClayLayout.Row>
                                        <ClayLayout.Col size={2} className="mt-1">Error Release: </ClayLayout.Col>
                                        <ClayLayout.Col size={2}>
                                            <FormControl
                                                control="text"
                                                name="errorRelease"
                                                //label="First Name"
                                                autoComplete={"off"}
                                                required={true}
                                                onChange={(e:any)=>{
                                                    updateErrorRelease(e.currentTarget.value)
                                                }}
                                            />
                                        </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated By: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{catalogDetail[2]?.updatedBy}</ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated Date: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{dateFormat(catalogDetail[2]?.updateDate)}</ClayLayout.Col>
                                    </ClayLayout.Row>
                                    <ClayLayout.Row>
                                        <ClayLayout.Col size={2} className="mt-1">Warning Check: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} >
                                            <div className="mt-1 d-flex justify-content-start">
                                                <ClayToggle
                                                toggled={warningToggle}
                                                onToggle={(toggleValue) => {
                                                    toggleWarning(toggleValue)
                                                }}
                                                />
                                            </div>
                                        </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated By: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{catalogDetail[1]?.updatedBy}</ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated Date: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{dateFormat(catalogDetail[1]?.updateDate)}</ClayLayout.Col>
                                    </ClayLayout.Row>
                                    <ClayLayout.Row>
                                        <ClayLayout.Col size={2} className="mt-1">Error Check: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} >
                                            <div className="mt-1 d-flex justify-content-start">
                                                <ClayToggle
                                                toggled={errorToggle}
                                                onToggle={(toggleValue) => {
                                                    toggleError(toggleValue)
                                                }}
                                                />
                                            </div>
                                        </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated By: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{catalogDetail[0]?.updatedBy}</ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">Last Updated Date: </ClayLayout.Col>
                                        <ClayLayout.Col size={2} className="mt-1">{dateFormat(catalogDetail[0]?.updateDate)}</ClayLayout.Col>
                                    </ClayLayout.Row>
                                    <ClayLayout.Row justify="end" className="align-items-center">
                                        <ClayLayout.Col className="d-flex align-items-center flex-row-reverse">
                                            
                                            <ClayButton
                                                className="ml-3"
                                                type="submit"
                                                displayType="primary"
                                                disabled = {!updateEnable}
                                            >
                                                Submit
                                            </ClayButton>
                                            <ClayButton
                                                className="ml-3"
                                                displayType="secondary"
                                                onClick={resetDetail}
                                            >
                                                Reset
                                            </ClayButton>
                                        </ClayLayout.Col>
                                    </ClayLayout.Row>
                                </ClayForm>
                                )}
                            </Formik>
                        </ClayCard.Body>
                    </ClayCard>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
        </>
    )
}

export default ProductCatalogManagement;