import ClayButton from "@clayui/button";
import { ClayInput } from "@clayui/form";
import ClayIcon from "@clayui/icon";
import ClayModal, { useModal } from "@clayui/modal";
import { ConfirmSendModal, Table } from "@common-components";
import { ROLE_SUPER_ADMIN } from "@constants";
import {
  getModalStatus,
  loadSiteDetailBySiteId,
  updateModal,
  updateSiteDetails,
} from "@store/siteDetails/slice";
import { GET_USER_ROLE } from "@utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import claySpriteImagePath from "../../../../common-ui-modules/images/clay/sprite.svg";
import SiteModalTableRenderer from "./SiteModalTableRenderer";
import {
  FEEDBACK_OPTIONS,
  FeedBackHeaderRenderer,
  getCommDecommDate,
  getConfirmHeaderText,
} from "./constants";

let originalItemsObj: any = {};
let originalSerialNumberData: any = {};

let isSaveDisabled = true;

export default function SiteModal(props: any) {
  const dispatch = useDispatch();
  const updateModalStatus = useSelector(getModalStatus);
  const role = GET_USER_ROLE();

  const { observer } = useModal({
    onClose: () => dispatch(updateModal("")),
  });
  const { isModalOpen, setIsModalOpen, formData, siteDetail } = props;

  const [searchValue, setSearchValue] = useState<string>("");
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [items, setItems] = useState([] as any);

  //To Listen for changes in modal
  const onChange = (value: string, serialNumber: string, index: number, type: string) => {
    const newItems = [...items];
    newItems[index][type] = value;
    originalItemsObj[serialNumber][type] = value;
    const valueChanged =
      originalSerialNumberData[serialNumber].status !== originalItemsObj[serialNumber].status;
    const commDecommChanged =
      originalSerialNumberData[serialNumber].commDecommDate !==
        originalItemsObj[serialNumber].commDecommDate ||
      originalSerialNumberData[serialNumber].internalComments !==
        originalItemsObj[serialNumber].internalComments;
    // commDecommFlag = true => when comm/decomm date is changed
    // changed=true when serial number status is changed
    // feedbackChanged=true when feedback is changed
    if (type === "status") {
      newItems[index].changed = valueChanged;
      newItems[index].feedback = "";
      newItems[index].comments = "";
      newItems[index].commDecommFlag = commDecommChanged;
      originalItemsObj[serialNumber].feedback = "";
      originalItemsObj[serialNumber].comments = "";
      originalItemsObj[serialNumber].changed = valueChanged;
      originalItemsObj[serialNumber].commDecommFlag = commDecommChanged;
      value === "A" && (originalItemsObj[serialNumber].feedbackChanged = true);
    } else {
      //To enable FeedbackChanged flag when feedback is changed
      //No Flag updates when comments is changed
      const feedbackChanged =
        originalSerialNumberData[serialNumber].feedback !==
          originalItemsObj[serialNumber].feedback ||
        originalSerialNumberData[serialNumber].comments !== originalItemsObj[serialNumber].comments;
      const internalFeedbackChanged =
        originalSerialNumberData[serialNumber].internalComments !==
        originalItemsObj[serialNumber].internalComments;
      if (!!feedbackChanged || !!internalFeedbackChanged) {
        originalItemsObj[serialNumber].feedbackChangedInternal = true;
      }
      originalItemsObj[serialNumber].feedbackChanged = !!feedbackChanged;
    }

    isSaveDisabled =
      Object.values(originalItemsObj).filter(
        (obj: any) =>
          (obj.feedback === "Others" && obj.comments.trim() === "") ||
          (obj.changed && obj.status !== "A" && obj.feedback !== "Others" && obj.feedback === ""),
      ).length > 0;
    //If Save disabled and billing site Id and role = "super admin" check if comm/decomm date is selected
    // if (
    //   !isSaveDisabled &&
    //   role === ROLE_SUPER_ADMIN &&
    //   formData.trueUpPeriodStartDate &&
    //   formData.trueUpPeriodEndDate
    // ) {
    //   //To disable Save Button for super admins when comm/decomm is null
    //   isSaveDisabled =
    //     Object.values(originalItemsObj).filter(
    //       (obj: any) =>
    //         obj.changed &&
    //         (!obj.commissionedDate || !obj.decommissionedDate ? !obj.commDecommDate : false),
    //     ).length > 0;
    // }
    setItems(newItems);
  };

  let STATUS_COLUMNS: any = [
    {
      headerName: "Serial Number",
      field: "serialNumber",
      filter: { show: false },
    },
    {
      headerName: "Status",
      width: "3%",
      field: "status",
      filter: { show: false },
      cellRenderer: <SiteModalTableRenderer type="status" onChange={onChange} />,
    },
    {
      headerName: "Feedback",
      renderer: <FeedBackHeaderRenderer />,
      field: "feedback",
      filter: { show: false },
      cellRenderer: (
        <SiteModalTableRenderer
          type="feedback"
          feedbackOptions={FEEDBACK_OPTIONS}
          onChange={onChange}
        />
      ),
    },
  ];

  //TO enable comissioned and decommissioned date for super admins only and for billing site ID's
  // if (role === ROLE_SUPER_ADMIN && formData.trueUpPeriodStartDate && formData.trueUpPeriodEndDate) {
  //   STATUS_COLUMNS.push(
  //     {
  //       headerName: "Comissioned/Decomissioned Date",
  //       renderer: <FeedBackHeaderRenderer name="Commissioned/Decommissioned Date" />,
  //       field: "commDecommDate",
  //       filter: { show: false },
  //       cellRenderer: (
  //         <SiteModalTableRenderer
  //           type="datepicker"
  //           onChange={onChange}
  //           trueUpStartDate={formData.trueUpPeriodStartDate}
  //           trueUpEndDate={formData.trueUpPeriodEndDate}
  //         />
  //       ),
  //       width: "10rem",
  //     },
  //     {
  //       headerName: "Comments",
  //       renderer: <FeedBackHeaderRenderer name="Comments" required={false} />,
  //       field: "internalComments",
  //       filter: { show: false },
  //       cellRenderer: <SiteModalTableRenderer type="textarea" onChange={onChange} />,
  //       width: "10rem",
  //     },
  //   );
  // }

  useEffect(() => {
    if (formData) {
      originalItemsObj = {};
      originalSerialNumberData = {};
      //To filter serial numbers with status = "R"
      const filteredData = formData.serialNumberDetails.filter((i: any) => {
        return i.status !== "R";
      });
      filteredData.forEach((item: any) => {
        const { serialNumber, status, feedback, comments, internalComments } = item;
        originalSerialNumberData[serialNumber] = { status, feedback, comments, internalComments };
        originalItemsObj[serialNumber] = {
          serialNumber,
          status,
          feedback,
          comments,
          changed: false,
          feedbackChanged: false,
          commDecommDate: "",
          commissionedDate: item.commissionedDate,
          decommissionedDate: item.decommissionedDate,
          internalComments: item.internalComments,
          commDecommFlag: false,
          feedbackChangedInternal: false,
        };
      });
      isSaveDisabled = true;
      setItems(Object.values(originalItemsObj));
    }
  }, [formData]);

  useEffect(() => {
    if (updateModalStatus) {
      dispatch(loadSiteDetailBySiteId(siteDetail.siteId || ""));
      onModalClose();
      dispatch(updateModal(""));
    }
  }, [updateModalStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  //On Modal Save Click
  const onSave = (fromOuterModal = false) => {
    const serialNumberDecommisionedAuditDtoList: any = [];
    let changed = true;
    Object.values(originalItemsObj).forEach((item: any) => {
      if (changed && item.feedbackChanged && !item.changed) {
        changed = item.changed;
      }
      if (
        item.commDecommDate ||
        item.feedback ||
        item.feedbackChangedInternal ||
        (item.status === "A" && item.changed)
      ) {
        serialNumberDecommisionedAuditDtoList.push(item);
      }
    });
    if (fromOuterModal && !changed) {
      setConfirmModal(true);
    } else {
      const data = {
        siteId: siteDetail.siteId,
        siteName: siteDetail.siteName || "",
        serialNumberDecommisionedAuditDtoList,
      };
      setConfirmModal(false);
      const newData = getCommDecommDate(data, role);
      if (!newData) {
        onModalClose();
      } else {
        dispatch(updateSiteDetails({ ...newData }));
      }
    }
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setSearchValue("");
  };

  const handleOnChange = (e: any) => {
    const searchvalue = e.target.value;
    const newItems =
      searchvalue !== ""
        ? [...Object.values(originalItemsObj)].filter((obj: any) =>
            obj.serialNumber.toLowerCase().includes(searchvalue.toLowerCase()),
          )
        : Object.values(originalItemsObj);

    setSearchValue(searchvalue);
    setItems([...newItems]);
  };

  let confirmModalText = "";
  if (showConfirmModal) {
    confirmModalText = getConfirmHeaderText(role);
  }

  return (
    <div>
      {isModalOpen && (
        <>
          <ClayModal
            observer={observer}
            size="lg"
            spritemap={claySpriteImagePath}
            disableAutoClose={true}
          >
            <ClayModal.Header>{"Manage Serial Numbers"}</ClayModal.Header>
            <ClayModal.Body>
              <div className="serial-container">
                <div className="serial-label">Serial Numbers: &emsp;</div>
                <ClayInput
                  type="text"
                  id={"siteIdsSelect"}
                  placeholder={"Search With Serial Number"}
                  value={searchValue}
                  onChange={handleOnChange}
                />
              </div>
              <Table
                definitions={STATUS_COLUMNS}
                dataSource={items}
                borderedColumns={false}
                borderless={true}
                headVerticalAlignment={"middle"}
                headingNoWrap={true}
                hover={false}
                noWrap={true}
                responsive={true}
                responsiveSize={"sm"}
                striped={true}
                tableVerticalAlignment={"middle"}
                hideFilter={true}
              />
            </ClayModal.Body>
            <ClayModal.Footer
              last={
                <ClayButton.Group spaced>
                  <ClayButton displayType="secondary" onClick={() => onModalClose()}>
                    <ClayIcon symbol="times" spritemap={claySpriteImagePath} />
                    &nbsp;{"Cancel"}
                  </ClayButton>
                  <ClayButton onClick={() => onSave(true)} disabled={isSaveDisabled}>
                    <ClayIcon symbol="disk" spritemap={claySpriteImagePath} />
                    &nbsp;{"Save"}
                  </ClayButton>
                </ClayButton.Group>
              }
            />
          </ClayModal>
          {showConfirmModal && (
            <ConfirmSendModal
              body={confirmModalText}
              confirmSend={onSave}
              modalClose={() => setConfirmModal(false)}
              confirmText="Confirm"
              closeText="Close"
            />
          )}
        </>
      )}
    </div>
  );
}
